(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("AS", [], factory);
	else if(typeof exports === 'object')
		exports["AS"] = factory();
	else
		root["AS"] = factory();
})(window, function() {
return 